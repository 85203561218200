body {
  font-family: sans-serif;;
  font-style: normal;
  background-color: #1a1d26;
  max-width: 100vw;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

select {
  display: block;
  margin: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23242835%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, transparent 0%, transparent 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 1rem top 0.9rem, 0 0;
  background-size: 0.65em auto, 100%;
  padding: 0.4rem 2rem 0.4rem 1rem;
  scrollbar-width: none;
  -ms-overflow-style: none;
  border: 1px solid #6370e5;
  border-radius: 8px;
  line-height: 1.5;
  color: #6370e5;
  transition: all 200ms ease-in-out;
  font-size: 0.889rem;
}

.networkSelect{
  width: 10%;
  justify-content: center;
  align-items: center;
  display: contents;
}

.HomeApp {
  height: 100%;
  width: 100%;
  background-color: #0d1116;    
  position: fixed;  
}


.blockNativeDivCSS{
  color: #fff;
  display: grid;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.blocknativeButton{
  background: #ffffffee;
  font-size: 0.889rem;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 0.55rem 1.4rem;
  color: #6370e5;
  margin: 0.4rem 0.75rem;
}

.selectChainDiv{
  color: #fff;
  display: grid;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}

.connectAndDisconnectDiv{
  color: #fff;
  display: grid;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  z-index: 10;
}

.disabledPage {
  pointer-events: none;
  opacity: 0.2;
}

.connectLocation{
  
}

.connectButtonStyle{
  top: 6.7vh;
  right: 5vw;
  display: flex;
  width: 15vw;
  height: 2.8vw;
  justify-content: center;
  align-items: center;
  border-radius: 2vw;
  font-family: Cofosans,sans-serif;
  color: #000;
  font-size: .8vw;
  line-height: 1;
  cursor: pointer;
  position: fixed;
  background-color: white;
}

.mainContainer {    
    
}

.mintButtonLocation{
  
}

.ContentHolder{
  color: #fff;
  position: fixed;
  left: 3vw;
  bottom: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 10;
}

.mintHeader {
  max-width: 400px;
  font-size: 32px;
  color: #fff;
}


.mintCopy {
  max-width: 500px;
  font-size: 18px;
  color: #fff;
}

.bgGraphic {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  background-color: #6370e5;

}

.mintButtonStyle{
  display: flex;
  width: 180px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-family: Cofosans,sans-serif;
  color: #000;
  font-size: 18px;
  line-height: 1;
  cursor: pointer;
  position: static;
  background-color: #CD66FF;
  bottom: 5vh;
  left: 3vw;
}

.presalemintButtonStyle{
  display: flex;
  width: 15vw;
  height: 2.5vw;
  justify-content: center;
  align-items: center;
  border-radius: 2vw;
  font-family: Cofosans,sans-serif;
  color: #000;
  font-size: .8vw;
  line-height: 1;
  cursor: pointer;
  position: fixed;
  background-color: #cfff48;
  bottom: 15vh;
  right: 32vw;
}

.ownermintButtonStyle{
  display: flex;
  width: 15vw;
  height: 2.5vw;
  justify-content: center;
  align-items: center;
  border-radius: 2vw;
  font-family: Cofosans,sans-serif;
  color: #000;
  font-size: .8vw;
  line-height: 1;
  cursor: pointer;
  position: fixed;
  background-color: #cfff48;
  bottom: 25vh;
  right: 32vw;
}

.feedbackButtonStyle{
  display: flex;
  width: 15vw;
  height: 2.5vw;
  justify-content: center;
  align-items: center;
  border-radius: 2vw;
  font-family: Cofosans,sans-serif;
  color: #000;
  font-size: .8vw;
  line-height: 1;
  cursor: pointer;
  position: fixed;
  background-color: #cfff48;
  bottom: 35vh;
  right: 32vw;
}

.swal-confirm{
  background-color: #cfff48;
  color: #000;
  border: 0;
  width: 60px;
  padding: 12px;
  border-radius: 0.25em;
  border-color: #fff;
  font-size: 1em;  
  cursor: pointer;
}

.swal-error{
  background-color: #fff;
  color: #000;
  border: 0;
  width: 60px;
  padding: 12px;
  border-radius: 0.25em;
  border-color: #fff;
  font-size: 1em;  
  cursor: pointer;
}

/* ----------------------------------------------------------------------------- Added Customs ---------------------------------------------------------------------------- */

.mintClickedDivCSS{  
  z-index: 28;
  left:0%;
  top: 0%;
  position: fixed;
}

.confirmClickedDivCSS{ 
  z-index: 25;
  left: 0%;
  top: 0%;
  position: fixed;
}

.loopingTxConfirmDivCSS{
  z-index: 25;
  left: 0%;
  top: 0%;
  position: fixed;
}

.successfullyMintedDivCSS{
  z-index: 25;
  left: 0%;
  top: 0%;
  position: fixed;
}

.txFailDivCSS{
  z-index: 25;
  left: 0%;
  top: 0%;
  position: fixed;
}

.gkIntCSS {
  z-index: 24;
  left: 0%;
  top: 0%;
  position: fixed;
}

.confirmVideoForMobile{
  visibility: hidden;
  position: fixed;
}

/* ----------------------------------------------------------------------------- Webflow Intro Class Inserts ---------------------------------------------------------------------------- */
 
.introcontentsmint {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 50;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.logohome {
  position: fixed;
  left: 50px;
  top: 50px;
  z-index: 100;
}

.introbrandimg {
  width: 150px;
  height: 150px;
}

.introcontents {
  position: fixed;
  left: 50px;
  bottom: 0px;
  z-index: 50;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 600px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  justify-content: flex-end;
}

.introheader {
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 500px;
  

 
}

.introheading {
  font-family: Moore-Henry, sans-serif;
  color: #d1c49d;
  font-size: 72px;
  line-height: 72px;
  font-weight: 400;
  letter-spacing: 4px;
  white-space: normal;
  
}

.intropara {
  max-width: 500px;
  margin-bottom: 0px;
  font-family: Moderat, sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
}

.intromintdetails {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 50;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 500px;
  min-height: 100px;
  border-style: solid;
  border-width: 4px;
  border-color: #d1c49d;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.72);
}

.intromintleftdiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
}

.mintfeature {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.mintfigures {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.mintcopy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.mintfeatureheading {
  margin-top: 0px;

  font-family: Moore-Henry, sans-serif;
  color: #d1c49d;
  font-size: 18px;
  line-height: 12px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 2px;
}

.mintfeatureheadinglarge {

  font-family: Moore-Henry, sans-serif;
  color: #d1c49d;
  font-size: 42px;
  font-weight: 400;
  line-height: 42px;
  text-align: center;
  letter-spacing: 2px;
}

.intromintbuttoncontain {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}

.intromintbutton {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 150px;
  height: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #d1c49d;
  font-family: Moderat, sans-serif;
  color: #000;
  font-size: 18px;
  line-height: 18px;
  text-decoration: none;
  cursor: pointer;
  top: 65px;
}

.intromintbuttonMobile {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 150px;
  height: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #d1c49d;
  font-family: Moderat, sans-serif;
  color: #000;
  font-size: 18px;
  line-height: 18px;
  text-decoration: none;
  cursor: pointer;
  top: 65px;
  visibility: hidden;
}

.intromintbuttonWhiteList {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 150px;
  height: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #d1c49d;
  font-family: Moderat, sans-serif;
  color: #000;
  font-size: 18px;
  line-height: 18px;
  text-decoration: none;
  cursor: pointer;
  bottom: 65px;
}

.intromintbuttonWhiteListMobile {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 150px;
  height: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #d1c49d;
  font-family: Moderat, sans-serif;
  color: #000;
  font-size: 18px;
  line-height: 18px;
  text-decoration: none;
  cursor: pointer;
  bottom: 65px;
  visibility: hidden;
}

.introconnectwallet {
  

  position: fixed;
  z-index: 50;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60vw;
  height: 50vh;
  padding-bottom: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 4px;
  border-color: #d1c49d;
  border-radius: 40px;
  background-color: rgba(0, 0, 0, 0.72);
  left: 20vw;
  top: 25vh;
}

.introconnectinner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 500px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.introconnectbutton {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  height: 60px;
  margin-top: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 9px;
  background-color: #d1c49d;
  font-family: Moderat, sans-serif;
  color: #000;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
}

.introconnectheading {
  margin-bottom: 0px;
  font-family: Moore-Henry, sans-serif;
  color: #d1c49d;
  font-size: 72px;
  line-height: 72px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 4px;
  white-space: normal;
}

.introconnectpara {
  max-width: 500px;
  margin-bottom: 0px;
  font-family: Moderat, sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.ethImg {
  height: 40px;
  width: 40px;
}

/* ----------------------------------------------------------------------------- Webflow Outro Class Inserts ---------------------------------------------------------------------------- */

.success_contents {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 50;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.back-home {
  position: fixed;
  left: 50px;
  top: 50px;
}

.backhomebutton {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  color: #fff;
  display: flex;
  width: 150px;
  height: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: rgba(29, 13, 8, 0.81);
  font-family: Moderat, sans-serif;
}

.success-contents {
  position: fixed;
  left: 50px;
  bottom: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 600px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.successheading {
  font-family: Moore-Henry, sans-serif;
  color: #d1c49d;
  font-size: 72px;
  line-height: 72px;
  font-weight: 400;
  letter-spacing: 2px;
}

.successsheader {
  position: static;
  width: 90vw;
  height: 100px


}

.success_buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.successdiscord {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  height: 50px;
  margin-right: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #d1c49d;
  font-family: Moderat, sans-serif;
  color: #000;
  font-weight: 500;
}

.successviewnft {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  height: 50px;
  margin-right: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #1d0d08;
  font-family: Moderat, sans-serif;
  color: #fff;
  font-weight: 500;
}

.outropara {
  max-width: 500px;
  margin-bottom: 15px;
  font-family: Moderat, sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
}

.loadingText{
  font-family: Moore-Henry, sans-serif;
  color: #d1c49d;
  font-size: 52px;
  line-height: 72px;
  font-weight: 400;
  letter-spacing: 4px;
  white-space: normal;
  bottom: 10px;
  left: 35vw;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 30vw;
  z-index: 50;
  opacity: 1;
}

.feedbackDivCSS{
  font-family: Moderat, sans-serif;
  color: #d1c49d;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  letter-spacing: 4px;
  white-space: normal;
  bottom: 180px;
  right: 50px;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 30vw;
  z-index: 50;
  
}

.mobileBackground{
  visibility: hidden;
}

.mobileSuccessBackground{
  visibility: hidden;
}

.backgroundVideoCSS{
  position: "absolute";
  height:"100%";
  width:"100%";
  object-fit:"cover";
  z-index: "0";
}


/* ------------------------------------------------------------------------------------------------------------------------------------------------------- */



@media screen and (max-width: 479px) { 

  .connectButtonStyle{
    width: 40vw;
    height: 5vh;
    position: fixed;
    top: 10vh;
    right: 5vw;
    margin-left: 0px;
    font-family: Cofosans, sans-serif;
    font-size: 3.5vw;
    font-weight: 400;
    border-color: white;    
  }
  
  /* ----------------------------------------------------------------------------- MOBILE Intro Class Inserts ---------------------------------------------------------------------------- */

  .introcontentsmint {
   
  }

  /* get rid off videos from mobile */
  .backgroundMobileCSS{
    display: none;
    visibility: hidden;
  }

  .outro{
    display: none;
    visibility: hidden;
  }

  .mobileBackground{
    visibility: visible;
    height: 100vh;
  }
  #gkIntID{
    visibility: hidden;
  }

  .mobileSuccessBackgroundCSS{
    z-index: 10;
    position: fixed;
    height: 100vh;
    top: 0;
  }

 

  .bgGraphic{
    visibility: hidden;
  }


  .backgroundVideoCSS{
    display: none;
    visibility: hidden;
  }

  .mintClickedDivCSS{
    display: none;
    visibility: hidden;
  }

  .confirmVideoForMobile{
    display: none;
    visibility: hidden;
  }

  #txSign{
    display: none;
    visibility: hidden;
  }

  #txLoop{
    display: none;
    visibility: hidden;
  }

  .successfullyMintedDivCSS{
    display: none;
    visibility: hidden;
  }

  .txFailDivCSS{
    display: none;
    visibility: hidden;
  }





















  .logohome {
    left: 5vw;
    top: 5vw;
  }

  .introbrandimg {
    width: 75px;
    height: 75px;
  }

  .introcontents {
    left: 5vw;
    bottom: 40px;
    width: 90vw;
  }

  .introheader {
    position: fixed;
    left: 5vw;
    top: 15vw;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 90vw;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .introheading {
    display: none;
    font-size: 15vw;
  }

  .intropara {
    display: none;
    font-size: 16px;
    font-weight: 500;
  }

  .intromintdetails {
    right: 5vw;
    width: 90vw;    
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 15px;
    height: 170px;
    bottom: 15px;
  }

  .feedbackDivCSS{
    width: 90vw;
    font-size: 18px;
    position: fixed;
    left: 5vw;
    line-height: 24px;
  }
  

  .ethImg {   
    margin-top: 20px;
  }

  .intromintleftdiv {
    width: 90%;
  }

  .mintfeatureheading {
    font-size: 14px;
  }

  .mintfeatureheadinglarge {
    margin-bottom: 5px;
    font-size: 44px;
  }

  .intromintbuttoncontain {
    width: 90%;
    margin-top: 15px;
  }

  .intromintbutton {
  
    display: none;
  }

  .intromintbuttonMobile {
    visibility: visible;
    width: 45%;
    left: 10px;
    top: auto;
    bottom: 10px;
  }

  .intromintbuttonWhiteList{
    display: none;
  }

  .intromintbuttonWhiteListMobile{
    visibility: visible;
    width: 45%;
    top: auto;
    right: 10px;
    bottom: 10px;
  }


  .introconnectwallet {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 90vw;
    border-radius: 20px;
    left: 5vw;
    height: auto;
  }

  .introconnectbutton {
    width: 100%;
    text-decoration: none;
  }

  .introconnectheading {
    font-size: 15vw;
    line-height: 15.1vw;
    text-align: left;
  }

  .introconnectpara {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
  }



/* ----------------------------------------------------------------------------- MOBILE Outro Class Inserts ---------------------------------------------------------------------------- */

.success_contents {
  background-color: #210e07;
  background-position: 50% 40%, 0px 0px;
  background-size: 150vw, auto;
  background-repeat: no-repeat, repeat;
}

.back-home {
  left: 5vw;
  top: 5vw;
}

.success-contents {
  left: 5vw;
  bottom: 40px;
  width: 90vw;
}

.successsheader {
  position: fixed;
  left: 5vw;
  top: 15vw;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90vw;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.successheading {
  font-size: 15vw;
}

.success_buttons {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.successdiscord {
  width: 90vw;
  margin-right: 0px;
  margin-bottom: 10px;
}

.successviewnft {
  width: 90vw;
  margin-right: 0px;
}

.outropara {
  display: none;
  font-size: 16px;
  font-weight: 500;
}





}


/* loding */
*,
*:before,
*:after {
  box-sizing: border-box;
}

.loading {
  width: 100%;
  /* height: 100%;  */
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: .5rem;
  row-gap: 1.5rem;
  position: fixed;
  bottom: 50px;
}

.loading:after {
  /* content: "Logging you in"; */
  color: #d1c49d;
  flex: 0 100%;
  font: 700 1.3rem "Caveat", cursive;
  text-align: center;
}

.loading div {
  background-color: #d1c49d;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: loading-effect 1s cubic-bezier(.77, .8, .58, 1) infinite
    var(--delay, .2s) alternate-reverse;
}

.loading div:nth-child(2) {
  --delay: .4s;
}
.loading div:nth-child(3) {
  --delay: .6s;
}
.loading div:nth-child(4) {
  --delay: .8s;
}
.loading div:nth-child(5) {
  --delay: 1s;
}

.fade-in {
  opacity: 1;
  visibility: visible;
  transition: opacity 3s;
}

.fade-out {
  opacity: 0;
  transition: opacity 2s ease;
}

@keyframes loading-effect {
  0% {
    box-shadow: 0 0 4px 1px #fff3;
    opacity: .2;
    transform: translateY(3px) scale(1.1);
  }

  100% {
    opacity: .8;
    transform: translateY(-3px);
  }
}



/* This is for editing the blocknative pop up window   */
/* https://docs.blocknative.com/onboard/core */
:root {  
  --onboard-font-family-normal: monospace; 
  --onboard-font-family-semibold: monospace; 
  --onboard-connect-content-width: 50vw; 
  --onboard-connect-content-height: 450px;
  --onboard-wallet-columns: 3;  
  --onboard-connect-sidebar-color: #d1c49d; 
  --onboard-connect-sidebar-progress-background: #fffb96;
  --onboard-connect-sidebar-progress-color: #030303; 
  --onboard-connect-header-color: #d1c49d;  
  --onboard-link-color: blue;
  --onboard-close-button-background: #030303;  
  --onboard-close-button-color: white;
  --onboard-checkbox-background: green;
  --onboard-checkbox-color: #d1c49d;
  --onboard-wallet-button-background-hover: #EFB1FF; 
  /*--onboard-wallet-button-background-hover: rgba(161, 219, 243, 0.863); */
  --onboard-wallet-button-color: #444444; 
  --onboard-wallet-button-border-color: #b967FF;
  --onboard-wallet-app-icon-border-color: #b967FF;
  --onboard-connect-sidebar-background: #030303;
  --onboard-connect-header-background: #030303;
  --onboard-main-scroll-container-background: #FAF1F1;
  --onboard-white: #FAF1F1;
  --onboard-gray-100: #d1c49d;
  --onboard-black: white;
  --onboard-primary-100: #F9F1F1;
  --onboard-gray-600: #030303;
  --onboard-action-required-modal-background: black;
  --account-select-modal-background: black;
}



@font-face {
  font-family: 'Cofosans';
  src: url('./fonts/COFOSANS-BOLD.TTF') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cofosans';
  src: url('./fonts/COFOSANS-MEDIUM.TTF') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cofosans';
  src: url('./fonts/COFOSANS-REGULAR.TTF') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cofosans';
  src: url('./fonts/COFOSANS-BLACK.TTF') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mortend';
  src: url('./fonts/Mortend---Bold.ttf') format('truetype'), url('./fonts/Mortend---Extrabold.ttf') format('truetype'), url('./fonts/Mortend---Bold.otf') format('opentype'), url('./fonts/Mortend---Extrabold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mortend';
  src: url('./fonts/Mortend---Regular.ttf') format('truetype'), url('./fonts/Mortend---Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mortend';
  src: url('./fonts/Mortend---Light.ttf') format('truetype'), url('./fonts/Mortend---Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mortend outline';
  src: url('./fonts/Mortend---Extrabold-Outline.ttf') format('truetype'), url('./fonts/Mortend---Extrabold-Outline.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Moore-Henry';
  src: url('./fonts/Moore-Henry.otf') format('truetype'), url('./fonts/Moore-Henry.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Moderat';
  src: url('./fonts/Moderat-Medium.otf') format('truetype'), url('./fonts/Moderat-Medium.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}





